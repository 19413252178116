import React from "react";
import { BrowserRouter, Switch, Route, Redirect, RouteProps } from "react-router-dom";
import {
	/* PLOP_ROUTE_IMPORT */
	BancoDeTalentos,
	Favoritos,
	HubPosGraduacao,
	SejaPlus,
	Contratos,
	CommentDetail,
	ResetPassword,
	MultimediaDetails,
	PublicProfile,
	Redirection,
	ArticleDetail,
	Downloads,
	Articles,
	Multimidias,
	Courses,
	Home,
	CourseDetail,
	Login,
	Comunity,
	ComunityDetail,
	StudentProfile,
	NotificationList,
} from "./pages";
import { Loading } from "./components/elements";
import { useUserContext } from "./context/user/AuthContext";
// import { isAuthenticated } from "./services/auth";

interface IRouteProps extends RouteProps {
	isPublic?: boolean;
	isPlus?: boolean;
}

const CustomRoute = ({ isPublic, isPlus, ...rest }: IRouteProps) => {
	const { loading, authenticated, userData } = useUserContext();
	if (loading) {
		return <Loading />;
	}

	if (isPlus && authenticated) {
		// tratar o redirect pro caso do usuário clicar num link para uma área que ele não tem permissão

		const userGroups = userData.grupos.split(",");
		if (
			userGroups.includes("Alunos") ||
			userGroups.includes("NMAAlunos") ||
			userGroups.includes("Mentores") ||
			userGroups.includes("Tutor") ||
			userGroups.includes("CIA30Dias") ||
			userGroups.includes("CIA_plus_empresa") ||
			userGroups.includes("NMA30dias")) {
			return <Route {...rest} />;
		} else {
			return <Redirect to='/cursos' />;
		}
	}

	if (!isPublic && !authenticated) {
		if (window.location.pathname !== "/") {
			localStorage.setItem("redirectUrl", window.location.pathname);
		}
		return <Redirect to='/' />;
	}

	return <Route {...rest} />;
};

export default function Routes() {
	return (
		<BrowserRouter>
			<Switch>
				<CustomRoute isPublic exact path='/' component={Login} />
				<CustomRoute isPublic exact path='/esqueci-senha' component={ResetPassword} />
				/* PLOP_INJECT_ROUTE */
				<CustomRoute exact path="/banco-de-talentos" component={ BancoDeTalentos } />
				<CustomRoute exact path="/Favoritos" component={Favoritos} />
				<CustomRoute exact path='/pos-graduacao' component={HubPosGraduacao} />
				<CustomRoute exact path='/seja-plus' component={SejaPlus} />
				<CustomRoute exact path='/contratos/:id' component={Contratos} />
				<CustomRoute exact path='/perfil/:id' component={PublicProfile} />
				<CustomRoute exact path='/redirection' component={Redirection} />
				<CustomRoute exact path='/notificacoes' component={NotificationList} />
				<CustomRoute exact path='/meu-perfil' component={StudentProfile} />
				<CustomRoute exact path='/cursos' component={Courses} />
				<CustomRoute exact path='/cursos/:slug/:aulaid?/:commentid?' component={CourseDetail} />
				<CustomRoute exact path='/detalhe-comentario/:debateId/:commentId' component={CommentDetail} />
				<CustomRoute exact isPlus path='/dashboard' component={Home} />
				<CustomRoute exact isPlus path={["/materiais", "/materiais/categoria/:category?"]} component={Downloads} />
				<CustomRoute exact isPlus path={["/artigos", "/artigos/categoria/:category?"]} component={Articles} />
				<CustomRoute exact isPlus path='/artigo/:slug' component={ArticleDetail} />
				<CustomRoute exact isPlus path='/multimidias' component={Multimidias} />
				<CustomRoute exact isPlus path='/serie/:slug/:id?' component={MultimediaDetails} />
				<CustomRoute exact isPlus path={["/noticias", "/noticias/categoria/:slug?"]} component={Comunity} />
				<CustomRoute exact isPlus path='/noticias/:comunityId' component={ComunityDetail} />
				<CustomRoute
					exact
					path='/sociautism'
					component={() => {
						// sim, isso é uma gambi pra funcionar link externo no MUI Drawer
						window.open("https://sociautism.ciaautismo.com.br/", "_blank");

						return <Redirect to='/redirection' />;
					}}
				/>
				<CustomRoute
					exact
					path='/indicacao'
					component={() => {
						// sim, isso é uma gambi pra funcionar link externo no MUI Drawer
						window.open("http://cursoaba.academiadoautismo.com.br/indicacao/?bl_ref=DW306", "_blank");

						return <Redirect to='/redirection' />;
					}}
				/>
			</Switch>
		</BrowserRouter>
	);
}
