import styled from 'styled-components'
import { Props } from './index';
import { lighten } from 'polished';

const BancoDeTalentosContainer = styled.div<Props>`
  padding: 20px;
  .flexCandidatos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    .curriculoCard {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 20px;
      border-radius: 10px;
      background: white;
      box-shadow: 0px 0px 11px -1px rgba(0,0,0,0.3);
      text-decoration: none;
      color: ${props => props.theme.color.primary};
      width: 100%;
      max-width: 400px;
      .foto {
        display: block;
        width: 80px;
        height: 80px;
        border-radius: 5px;
        overflow: hidden;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      big {
        font-size: 1.2rem;
        font-weight: 600;
        display: block;
        margin-bottom: 5px;
      }
    }
  }
  .modalCurriculo {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    .overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,0.3);
      z-index: 10;
    }
    .modalCurriculoBody {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 700px;
      max-width: 90%;
      max-height: 90vh;
      background: white;
      z-index: 10;
      border-radius: 16px;
      padding: 16px;
      color: ${props => props.theme.color.primary};
      .foto {
        display: block;
        width: 100px;
        height: 100px;
        border-radius: 5px;
        overflow: hidden;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .topo {
        margin: 10px 0;
        padding: 0 0 10px;
        border-bottom: 1px dotted #CCC;
      }
      .caracteristicas {
        margin: 12px 0;
        > div {
          margin-bottom: 4px;
        }
        ul {
          display: flex;
          flex-wrap: wrap;
          gap: 3px;
          list-style: none;
          margin-top: 4px;
          li {
            /* display: inline-block; */
            font-size: .9rem;
            background: rgba(0,0,0,0.1);
            padding: 6px 10px;
            border-radius: 3px;
          }
        }
      }
      .downloadPdf {
        a {
          display: block;
          text-align: center;
          background-color: ${props => props.theme.color.primary};
          color: white;
          text-decoration: none;
          transition: all .3s;
          font-weight: 700;
          margin-top: 30px;
          padding: 16px 10px;
          border-radius: 8px;
          text-transform: uppercase;
          &:hover {
            background-color: ${(props) => lighten(0.4, props.theme.color.primary)};
          }
        }
      }
    }
  }
`
export { BancoDeTalentosContainer }