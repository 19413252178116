import api from "./api";
import BaseService from "./base.service";

export default class CurriculossService extends BaseService {
	constructor(page?: number | string) {
		if (page) {
			super(`usuarios/curriculos?page=${page}`);
		}
		else {
			super(`usuarios/curriculos`);
		}
	}

	async meuCurriculo() {
		try {
			const response = await api.get(`usuarios/meu-curriculo/`);
			// console.log("Material Baixado => ", response.data);
			return response.data;
		} catch (err) {
			console.error(`erro ao marcar material como visto ${this.resource} :`, err);
			//console.log(`erro ao listar ${this.resource} :`, err.response);
			return {
				"profissao": 0,
				"texto": "",
				"mudancas": 0,
				"exibir_curriculo": false,
				"arquivo": null
			};
		}
	}
}
