import React, { useCallback, useEffect } from "react";
import clsx from "clsx";
import { NavLink } from "react-router-dom";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Hidden from "@material-ui/core/Hidden";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { useUserContext } from "../../../context/user/AuthContext";

import { IoIosMenu } from "react-icons/io";
import { FaMedal } from "react-icons/fa";
import {
	IconWrapper, //
	SidebarIconWrapper,
	ComunitySidebarIcon,
	CourseSidebarIcon,
	DashboardSidebarIcon,
	MultimidiaSidebarIcon,
	DownloadsSidebarIcon,
	ArticlesSidebarIcon,
	SociautismIcon,
	PosIcon,
} from "../../elements/Icons";

import { trackGA } from "../../../helpers/trackEvent";
import {
	SidebarContainer,
} from "./SidebarStyled";
import { PresentIcon } from "../../elements/Icons/SVGIcons/Sidebar/presenticon";
import { AiOutlineStar } from "react-icons/ai";

export interface Props {
	logoUrl: string;
}

const drawerWidth = 240;
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		drawer: {
			width: drawerWidth,
			flexShrink: 0,
			whiteSpace: "nowrap",
		},
		drawerOpen: {
			width: drawerWidth,
			borderColor: "#f1f1f1",
			background: "#f1f1f1",
			transition: theme.transitions.create("width", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			}),
		},
		drawerClose: {
			borderColor: "#f1f1f1",
			background: "#f1f1f1",
			transition: theme.transitions.create("width", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			overflowX: "hidden",
			width: "75px",
			[theme.breakpoints.up("sm")]: {
				width: "75px",
			},
		},
		NavLink: {
			display: "flex",
			alignItems: "center",
			textDecoration: "none",
			fontWeight: 700,
			width: "100%",
			height: "100%",
			color: "#013A7B",
			fontSize: "13px",
		},
		paper: {
			backgroundColor: "#f1f1f1",
		},
	})
);

const SidebarWrapper: React.FC<Props> = ({ ...props }: Props) => {
	const classes = useStyles();
	const { isPlus, showPos, userData } = useUserContext();
	const [open, setOpen] = React.useState(false);
	const toggleDrawer = () => {
		setOpen(!open);
	};

	const menuItems = [
		{
			name: "Início",
			link: "/dashboard",
			restrictedToSubscribers: true,
			icon: <DashboardSidebarIcon color='#013A7B' />,
		},
		{
			name: "Artigos",
			link: "/artigos",
			restrictedToSubscribers: true,
			icon: <ArticlesSidebarIcon color='#013A7B' />,
		},
		{
			name: "Notícias",
			link: "/noticias",
			restrictedToSubscribers: true,
			icon: <ComunitySidebarIcon color='#013A7B' />,
		},
		{
			name: "Cursos",
			link: "/cursos",
			restrictedToSubscribers: false,
			icon: <CourseSidebarIcon color='#013A7B' />,
		},
		{
			name: "Materiais",
			link: "/materiais",
			restrictedToSubscribers: true,
			icon: <DownloadsSidebarIcon color='#013A7B' />,
		},
		{
			name: "Séries",
			link: "/multimidias",
			restrictedToSubscribers: true,
			icon: <MultimidiaSidebarIcon color='#013A7B' />,
		},
		// {
		// 	name: "Sociautism",
		// 	link: "/sociautism",
		// 	restrictedToSubscribers: true,
		// 	icon: <SociautismIcon color='#013A7B' />,
		// },
		// {
		// 	name: "Indique e Ganhe",
		// 	link: "/indicacao",
		// 	restrictedToSubscribers: false,
		// 	icon: <PresentIcon color='#013A7B' />,
		// },
	];

	useEffect(() => {
		// console.log("showpos", showPos());
		// console.log("poslink", posLink());
	}, [])

	const restrictedItem = useCallback((item) => {
		if (item.restrictedToSubscribers) {
			if (isPlus()) {
				return true;
			} else {
				return false;
			}
		} else {
			return true;
		}
	}, []);

	const posLink = () => {
		const userGroups = userData.grupos.split(",");
		const posT1 = userGroups.includes("PosGraduacaoT1");
		const posT2 = userGroups.includes("PosGraduacaoT2");
		const posT3 = userGroups.includes("PosGraduacaoT3");
		const posT4 = userGroups.includes("PosGraduacaoT4");
		const posT124 = userGroups.includes("PosGraduacaoT1-24");
		const posP = userGroups.includes("PosGraduacaoP");



		const tutor = userGroups.includes("Tutor");

		// console.log(userGroups)

		if (tutor || (posT1 && posT2)) {
			return "/pos-graduacao";
		}
		if (posT1) {
			return "/cursos/pos-graduacao-em-autismo";
		}
		if (posT2) {
			return "/cursos/pos-graduacao-em-autismo-t2";
		}
		if (posT3) {
			return "/cursos/pos-graduacao-em-autismo-t3";
		}
		if (posT4) {
			return "/cursos/pos-graduacao-em-autismo-t4";
		}
		if (posT124) {
			return "/cursos/pos-graduacao-em-autismo-24-t1";
		}
		if (posP) {
			return "/cursos/pos-graduacao-em-autismo-24-t2";
		}
		return "";
	};

	// useEffect(() => {
	// 	console.log("userdata", userData.grupos.split(","));
	// }, []);

	return (
		<SidebarContainer open={open}>
			<Hidden mdUp>
				<div className={isPlus() ? "handleDrawer isPlus" : "handleDrawer"} onClick={() => toggleDrawer()}>
					<IconWrapper filled={false} icon={<IoIosMenu size='30px' />} />
				</div>
				<Drawer classes={{ paper: classes.paper }} variant='temporary' open={open} onClick={() => toggleDrawer()}>
					<List>
						{menuItems.map((item, index) => (
							<React.Fragment key={index}>
								{restrictedItem(item) ? (
									<>
										<ListItem key={item.name}>
											<NavLink exact to={item.link} className={classes.NavLink} activeClassName='active' onClick={() => trackGA("nav", "click", item.name)}>
												<ListItemIcon>
													<SidebarIconWrapper svgIcon={item.icon} />
												</ListItemIcon>
												<ListItemText
													primary={item.name}
													secondary={item.restrictedToSubscribers && item.name !== "Início" ? "Plus" : ""}
													secondaryTypographyProps={{ style: { color: "#f2c94c", fontWeight: "bold" } }}
												/>
											</NavLink>
										</ListItem>
									</>
								) : (
									""
								)}
							</React.Fragment>
						))}
						<ListItem>
							<NavLink exact to="/favoritos" className={classes.NavLink} activeClassName='active' onClick={() => trackGA("nav", "click", "favoritos")}>
								<ListItemIcon>
									<SidebarIconWrapper svgIcon={<AiOutlineStar size={30} color="#f2c94c" />} />
								</ListItemIcon>
								<ListItemText
									primary="Favoritos"
								/>
							</NavLink>
						</ListItem>
						{showPos() && (
							<ListItem>
								<NavLink exact to={posLink()} className={classes.NavLink} activeClassName='active' onClick={() => trackGA("nav", "click", "Pós-Graduação")}>
									<ListItemIcon>
										<SidebarIconWrapper svgIcon={<PosIcon />} />
									</ListItemIcon>
									<ListItemText primary='Pós Graduação' />
								</NavLink>
							</ListItem>
						)}
						{!isPlus() && (
							<ListItem>
								<NavLink exact to='/seja-plus' className={classes.NavLink} activeClassName='active' onClick={() => trackGA("nav", "click", "Seja Plus")}>
									<ListItemIcon>
										<SidebarIconWrapper svgIcon={<FaMedal size={25} color='#013A7B' />} />
									</ListItemIcon>
									<ListItemText primary='Seja Plus' />
								</NavLink>
							</ListItem>
						)}
						{(userData.grupos.split(",").includes("Sociautism") || isPlus()) && (
							<ListItem>
								<NavLink exact to='/sociautism' className={classes.NavLink} activeClassName='active' onClick={() => trackGA("nav", "click", "Sociautism")}>
									<ListItemIcon>
										<SidebarIconWrapper svgIcon={<SociautismIcon color='#013A7B' />} />
									</ListItemIcon>
									<ListItemText
										primary='Sociautism'
										secondaryTypographyProps={{ style: { color: "#f2c94c", fontWeight: "bold" } }}
										secondary={isPlus() ? "Plus" : ""}
									/>
								</NavLink>
							</ListItem>
						)}
					</List>
				</Drawer>
			</Hidden>

			<Hidden smDown>
				<Drawer
					variant='permanent'
					onMouseEnter={() => setOpen(true)}
					onMouseLeave={() => setOpen(false)}
					className={clsx(classes.drawer, {
						[classes.drawerOpen]: open,
						[classes.drawerClose]: !open,
					})}
					classes={{
						paper: clsx({
							[classes.drawerOpen]: open,
							[classes.drawerClose]: !open,
						}),
					}}>
					<List>
						{/* Sim, esse primeiro item ta aqui só pq eu não quis dar o espaço pra tirar o primeiro item de baixo do header. Me julgue. */}
						<ListItem>
							<NavLink exact to='/dashboard' className={classes.NavLink} activeClassName='active'>
								<ListItemIcon>
									<SidebarIconWrapper svgIcon={<DashboardSidebarIcon color='#013A7B' />} />
								</ListItemIcon>
								<ListItemText primary='Dashboard' />
							</NavLink>
						</ListItem>
						{menuItems.map((item, index) => (
							<React.Fragment key={index}>
								{restrictedItem(item) ? (
									<>
										<ListItem key={item.name}>
											<NavLink exact to={item.link} className={classes.NavLink} activeClassName='active' onClick={() => trackGA("nav", "click", item.name)}>
												<ListItemIcon>
													<SidebarIconWrapper svgIcon={item.icon} />
												</ListItemIcon>
												<ListItemText
													primary={item.name}
													secondary={item.restrictedToSubscribers && item.name !== "Início" ? "Plus" : ""}
													secondaryTypographyProps={{ style: { color: "#f2c94c", fontWeight: "bold" } }}
												/>
											</NavLink>
										</ListItem>
									</>
								) : (
									""
								)}
							</React.Fragment>
						))}

						<ListItem>
							<NavLink exact to="/favoritos" className={classes.NavLink} activeClassName='active' onClick={() => trackGA("nav", "click", "favoritos")}>
								<ListItemIcon>
									<SidebarIconWrapper svgIcon={<AiOutlineStar size={30} color="#f2c94c" />} />
								</ListItemIcon>
								<ListItemText
									primary="Favoritos"
								/>
							</NavLink>
						</ListItem>
						{showPos() && (
							<ListItem>
								<NavLink exact to={posLink()} className={classes.NavLink} activeClassName='active' onClick={() => trackGA("nav", "click", "Pós-Graduação")}>
									<ListItemIcon>
										<SidebarIconWrapper svgIcon={<PosIcon />} />
									</ListItemIcon>
									<ListItemText primary='Pós Graduação' />
								</NavLink>
							</ListItem>
						)}

						{/* {isPlus().toString()} */}
						{!isPlus() && (
							<ListItem>
								<NavLink exact to='/seja-plus' className={classes.NavLink} activeClassName='active' onClick={() => trackGA("nav", "click", "Seja Plus")}>
									<ListItemIcon>
										<SidebarIconWrapper svgIcon={<FaMedal size={25} color='#013A7B' />} />
									</ListItemIcon>
									<ListItemText primary='Seja Plus' />
								</NavLink>
							</ListItem>
						)}
						{(userData.grupos.split(",").includes("Sociautism") || isPlus()) && (
							<ListItem>
								<NavLink exact to='/sociautism' className={classes.NavLink} activeClassName='active' onClick={() => trackGA("nav", "click", "Sociautism")}>
									<ListItemIcon>
										<SidebarIconWrapper svgIcon={<SociautismIcon color='#013A7B' />} />
									</ListItemIcon>
									<ListItemText
										primary='Sociautism'
										secondaryTypographyProps={{ style: { color: "#f2c94c", fontWeight: "bold" } }}
										secondary={isPlus() ? "Plus" : ""}
									/>
								</NavLink>
							</ListItem>
						)}
						{/* userData.grupos.split(",").includes("Sociautism") */}
					</List>
				</Drawer>
			</Hidden>
		</SidebarContainer>
	);
};

// SidebarWrapper.defaultProps = {
//   background: '#FFF'
// }

export { SidebarWrapper as Sidebar };
