import React, { useEffect, useState } from 'react';
import { ClassRatingsContainer } from './ClassRatingsStyled'
import { ClassRating } from '../../../ClassRating';
import ClassRatingService from '../../../../services/classRatings.service';

export interface Props {
  lessonID: number;
  currentLessonRating: number;
  onRate: (lid: number, rating: number) => void;
}

const ClassRatingsWrapper: React.FC<Props> = ({
  lessonID,
  currentLessonRating,
  onRate,
  // numberOfLessonRatings
}: Props) => {
  const [classRating, setClassRating] = useState<number>(0);
  const [teacherRating, setTeacherRating] = useState<number>(0);

  useEffect(() => {
    setClassRating(currentLessonRating)
  }, [currentLessonRating])

  const getClassStatus = async () => {
    const classRatingService = new ClassRatingService();
    const response = await classRatingService.status(lessonID);
    // console.log(response);
  }

  const rateClass = async (rating: number) => {
    const classRatingService = new ClassRatingService();
    // const response = 
    await classRatingService.rate(rating, lessonID, "");

    // console.log(response)
  }

  const setRating = (rating: number, type: "class" | "teacher") => {
    // console.log({ rating, type })
    if (type === "class") {
      rateClass(rating);
      setClassRating(rating);
      onRate(lessonID, rating);
    } else {
      setTeacherRating(rating)
    }
  }

  useEffect(() => {
    //getClassStatus();
  }, [])

  return (
    <ClassRatingsContainer>
      <h4>O que está achando da aula?</h4>
      <ClassRating lessonID={lessonID} current={classRating} onRate={(rate: number) => setRating(rate, "class")} />
    </ClassRatingsContainer>
  );
};

ClassRatingsWrapper.defaultProps = {

}

export { ClassRatingsWrapper as ClassRatings };