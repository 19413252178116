import React, { useState, useEffect } from "react";
import { Box, TextField } from "@material-ui/core";
import { ModalBase } from "../../Producer_bkp/ModalBase";
import StudentProfileService, { IStudentApiResponse } from "../../../../services/student.profile.service";
import api from "../../../../services/api";

export interface Props {
	student: IStudentApiResponse;
	onUpdateAction?: () => void;
}

const UpdateUserCurriculumWrapper: React.FC<Props> = ({ student, onUpdateAction, ...props }: Props) => {
	const [modalOpen, setModalOpen] = useState(false);
	const [nameInformation, setNameInformation] = useState("");

	const [oldPassword, setOldPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [newPasswordComfirm, setNewPasswordComfirm] = useState("");

	function handleModalClose() {
		setModalOpen(false);
	}

	function handleModalOpen() {
		setModalOpen(true);
	}
	async function handleUpdateStudentProfile(event?: React.FormEvent<HTMLFormElement>) {
		if (newPassword !== newPasswordComfirm) {
			setNameInformation("As senha nova não é igual à confirmação de senha");
			return;
		}
		if (event) {
			event.preventDefault();
		}
		try {
			const response = await api.post("/usuarios/trocar-senha/", {
				senha_anterior: oldPassword,
				nova_senha: newPassword,
				nova_senha2: newPasswordComfirm,
			});
			// console.log("TROCA DE SENHA", response);
		} catch (error) {
			console.error(error);
		}

		handleModalClose();
	}

	return (
		<>
			<Box display='flex' alignItems='center' justifyContent='center'>
				<span className='logoutPill' onClick={handleModalOpen}>
					Meu Currículo
				</span>
			</Box>
			<ModalBase title={`Alterar Senha`} open={modalOpen} handleClose={handleModalClose} saveAction={handleUpdateStudentProfile}>
				<Box display='flex' alignItems='center' padding='50px 100px'>
					<Box width='100%'>
						<form noValidate autoComplete='off' onSubmit={handleUpdateStudentProfile}>
							<Box display='grid' gridGap='20px' gridTemplateColumns={{ sm: "1fr", md: "1fr 1fr 1fr" }} marginTop='16px'>
								<TextField
									id='sena_anterior'
									type='password'
									label='Senha Antiga'
									margin='dense'
									value={oldPassword}
									onChange={(e) => {
										setOldPassword(e.target.value);
									}}
									helperText={nameInformation}
									error={nameInformation.length > 0}
									onFocus={() => setNameInformation("")}
									fullWidth
									required
								/>
								<TextField
									id='nova_senha'
									type='password'
									label='Senha Nova'
									margin='dense'
									value={newPassword}
									onChange={(e) => {
										setNewPassword(e.target.value);
									}}
									error={nameInformation.length > 0}
									helperText={nameInformation}
									onFocus={() => setNameInformation("")}
									fullWidth
									required
								/>

								<TextField
									id='nova_senha2'
									type='password'
									label='Confirme a nova senha'
									margin='dense'
									value={newPasswordComfirm}
									error={nameInformation.length > 0}
									helperText={nameInformation}
									onFocus={() => setNameInformation("")}
									onChange={(e) => {
										setNewPasswordComfirm(e.target.value);
									}}
									required
									fullWidth
								/>
							</Box>
						</form>
					</Box>
				</Box>
			</ModalBase>
		</>
	);
};

// UpdateUserProfileWrapper.defaultProps = {
// };

export { UpdateUserCurriculumWrapper as UpdateUserCurriculum };
