import React, {
  ChangeEvent, useRef, useEffect, useCallback, useState,
} from 'react';

import { useField } from '@unform/core';

import { ImageInputContainer } from './ImageInputStyled';
import uploadIcon from '../../../assets/images/upload-icon.svg';

interface Props {
    name: string;
    label?: string;
    rounded?: boolean;
}

type InputProps = JSX.IntrinsicElements['input'] & Props;

const ImageInput: React.FC<InputProps> = ({
  name, label, rounded, ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const {
    fieldName, registerField, defaultValue, error,
  } = useField(name);
  const [preview, setPreview] = useState(defaultValue);

  const handlePreview = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (!file) {
      setPreview(null);
    }

    // const previewURL = URL.createObjectURL(file);

    // setPreview(previewURL);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'files[0]',
      clearValue(ref: HTMLInputElement) {
        ref.value = '';
        setPreview(null);
      },
      setValue(_: HTMLInputElement, value: string) {
        setPreview(value);
      },
    });
  }, [fieldName, registerField]);

  return (
    <label htmlFor={fieldName}>
      <ImageInputContainer rounded={rounded}>
        <input
          className="image-input"
          type="file"
          id={fieldName}
          ref={inputRef}
          onChange={handlePreview}
          {...rest}
        />
        {preview
          ? (
            <div className="preview-wrapper">
              <img src={preview} alt="Preview" width="100" />
            </div>
          )
          : (
            <div className="no-preview">
              <div className="image-wrapper">
                <img src={uploadIcon} alt="Enviar Imagem" />
              </div>
              {label
              && <div className="text">{label}</div>}
            </div>
          )}

      </ImageInputContainer>
    </label>
  );
};
ImageInput.defaultProps = {
  rounded: false,
};
export { ImageInput };
